import React from 'react';
import { Link } from 'react-router-dom';
import logoWhite from "../../assets/logo-white.png"

const MobileMenu = () => {
  return (
    <div id="mobilemenu-popup" className="offcanvas mobile-menu-wrap">
      <div className="mobile-logo-wrap d-lg-none d-block">
        <div className="logo-holder">
          <a
            href="index.html"
            className="link-wrap single-logo light-mode img-height-100"
            aria-label="Site Logo"
          >
            <img
              width="180"
              height="46"
              src={logoWhite}
              alt="logo"
            />
          </a>
          <a
            href="index.html"
            className="link-wrap single-logo dark-mode img-height-100"
            aria-label="Site Logo"
          >
            <img
              width="180"
              height="46"
              src="assets/media/logo-white.png"
              alt="logo"
            />
          </a>
        </div>
        <button
          aria-label="Offcanvas"
          type="button"
          className="mobile-close"
          data-bs-dismiss="offcanvas"
        >
          <i className="bi bi-x-circle"></i>
        </button>
      </div>
      <nav id="dropdown" className="template-main-menu">
        <ul className="menu">
          <li className="menu-item">
            <Link to="/">Home</Link>
          </li>
          {/* <li className="menu-item">
            <Link to="/about">About</Link>
          </li> */}
          {/* <li className="menu-item menu-item-has-children">
              <a href="#">Pages</a>
              <ul className="sub-menu">
                <li className="menu-item menu-item-has-children second-level">
                  <a href="#">Archive Pages</a>
                  <ul className="sub-menu">
                    <li className="menu-item"><a href="archive-layout1.html">Archive Layout 1</a></li>
                    <li className="menu-item"><a href="archive-layout2.html">Archive Layout 2</a></li>
                    <li className="menu-item"><a href="archive-layout5.html">Archive Layout 3</a></li>
                  </ul>
                </li>
                <li className="menu-item menu-item-has-children second-level">
                  <a href="#">Other Pages</a>
                  <ul className="sub-menu">
                    <li className="menu-item"><a href="author.html">Author</a></li>
                    <li className="menu-item"><a href="404.html">404</a></li>
                  </ul>
                </li>
              </ul>
            </li> */}
          {/* <li className="menu-item menu-item-has-children">
              <a href="#">Single Blog</a>
              <ul className="sub-menu">
                <li className="menu-item"><a href="post-format-default.html">Single Default</a></li>
                <li className="menu-item"><a href="post-format-full-width-banner.html">Single Full Width Banner</a></li>
                <li className="menu-item"><a href="post-format-without-sidebar.html">Single Without Sidebar</a></li>
              </ul>
            </li> */}
          <li className="menu-item">
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default MobileMenu;
