import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

const HeroBanner = ({ data }) => {
  

  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div className="slick_next slick-arrow" onClick={onClick}>
        <i className="bi bi-arrow-right"></i>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="slick_prev slick-arrow" onClick={onClick}>
        <i className="bi bi-arrow-left"></i>
      </div>
    );
  }

  const carouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const mostRecent = data.reduce((prev, current) => {
    const prevDate = prev.recentBlog?.createdAt
      ? new Date(prev.recentBlog.createdAt)
      : new Date(0);
    const currentDate = current.recentBlog?.createdAt
      ? new Date(current.recentBlog.createdAt)
      : new Date(0);
    return currentDate > prevDate ? current : prev;
  }, {});

  const divStyle = {
    backgroundImage: `url(${mostRecent.recentBlog?.bannerImageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };
  

  return (
    <div>
      <section className="post-wrap-layout1 bg-color-light-1 transition-default heroBanner">
        <div className="container">
          <div className="row g-3">
            <div className="col-lg-7">
              <div className="post-box-layout1 box-border-dark-1 radius-default transition-default overflow-hidden">
                <div
                  id="videoPlayer-1"
                  className="image-mask videoPlayer-1 radius-medium"
                  style={divStyle}
                ></div>
                <div className="content-holder">
                  <h3 className="entry-title h3-large color-light-1-fixed underline-animation mb-0">
                    <Link to={`/blog/${mostRecent?.recentBlog?._id}`}>
                      {mostRecent?.recentBlog?.title}
                    </Link>
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <Slider {...carouselSettings}>
                {data.map((item) =>
                  item.recentBlog && item.recentBlog.bannerImageUrl !== null ? (
                    <div key={item._id} className="h-100">
                      <div
                        id="post-slider-1"
                        className="post-slider-1 gutter-6 initially-none h-100"
                      >
                        <div className="h-100">
                          <div className="h-100 post-box-layout2 box-border-dark-1 radius-default padding-30 bg-color-old-lace box-shadow-large shadow-style-1 transition-default">
                            <div className="figure-holder radius-default">
                              <Link
                                to={`/blog/${item.recentBlog._id}`}
                                className="link-wrap"
                              >
                                <img
                                  className="object-fit-cover"
                                  width="635"
                                  height="300"
                                  src={item.recentBlog.bannerImageUrl}
                                  alt="Post"
                                />
                              </Link>
                            </div>
                            <div className="content-holder">
                              <div className="entry-category style-1 color-dark-1-fixed">
                                <ul>
                                  <li>
                                    <Link to={`/blog/${item.recentBlog._id}`}>
                                      {item?.categoryName}
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <h3 className="entry-title color-dark-1-fixed underline-animation">
                                <Link
                                  to={`/blog/${item.recentBlog._id}`}
                                  className="link-wrap"
                                >
                                  {item?.recentBlog?.title}
                                </Link>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null
                )}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HeroBanner;
