import React, { useEffect, useState } from 'react';
import HeroArea from './HeroArea';
import HeroBanner from './HeroBanner';
import PostAreaCarousel from './PostAreaCarousel';
import TopStories from './TopStories';
import CategoryArea from './CategoryArea';
import LatestStories from './LatestStories';
// import TopVideos from './TopVideos';
import RecentArticles from './RecentArticles';
import NewRecentArticles from './NewRecentArticles';
import TopColumnist from './TopColumnist';
import CardContainer from './CardContainer';
import NewsLetterEmail from './NewsLetterEmail';
import { constant } from '../../utils/constant';
import { Skeleton } from '@mui/material';

const HeroBody = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchData = async () => {
    try {
      const response = await fetch(constant.CONST_SARCASM_BLOG_RECENT);
      const json = await response.json();
      if (json.success) {
        setData(json.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const Shimmer = () => (
    <div
      className="shimmer-placeholder"
      style={{
        height: '120px',
        width: '65%',
        backgroundColor: '#f0f0f0',
        marginBottom: '20px',
        margin: '0 auto',
      }}
    />
  );

  return (
    <div>
      <HeroArea />
      {loading ? <Skeleton variant="rectangular" height={120} width="65%" style={{ margin: '0 auto 20px' }} /> : <HeroBanner data={data} />}
      {loading ? <Skeleton variant="rectangular" height={120} width="65%" style={{ margin: '0 auto 20px' }} /> : <PostAreaCarousel data={data} />}
      {loading ? <Skeleton variant="rectangular" height={120} width="65%" style={{ margin: '0 auto 20px' }} /> : <TopStories data={data} />}
      {loading ? <Skeleton variant="rectangular" height={120} width="65%" style={{ margin: '0 auto 20px' }} /> : <CategoryArea data={data} />}
      {loading ? <Skeleton variant="rectangular" height={120} width="65%" style={{ margin: '0 auto 20px' }} /> : <LatestStories data={data} />}
      {/* {loading ? <Skeleton variant="rectangular" height={120} width="65%" style={{ margin: '0 auto 20px' }} /> : <TopVideos />} */}
      {loading ? <Skeleton variant="rectangular" height={120} width="65%" style={{ margin: '0 auto 20px' }} /> : <RecentArticles data={data} />}
      {loading ? <Skeleton variant="rectangular" height={120} width="65%" style={{ margin: '0 auto 20px' }} /> : <NewRecentArticles data={data} />}
      <TopColumnist />
      {loading ? <Skeleton variant="rectangular" height={120} width="65%" style={{ margin: '0 auto 20px' }} /> : <CardContainer data={data} />}
      <NewsLetterEmail />
    </div>
  );
};

export default HeroBody;
