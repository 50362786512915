import Slider from "react-slick";
import postImg3 from "../../assets/blog/post3.webp";
import postImg4 from "../../assets/blog/post4.webp";
import postImg5 from "../../assets/blog/post5.webp";
import postImg6 from "../../assets/blog/post6.webp";
import postImg61 from "../../assets/blog/post61.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="slick_next slick-arrow" onClick={onClick}>
      <i className="bi bi-arrow-right"></i>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="slick_prev slick-arrow" onClick={onClick}>
      <i className="bi bi-arrow-left"></i>
    </div>
  );
}

const PostAreaCarousel = ({ data }) => {
  // Slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <section className="post-wrap-layout2 space-top-50 bg-color-light-1 transition-default bannerBottom">
        <div className="container">
          <div className="position-relative">
            <Slider {...settings}>
              {data?.map((item) =>
                item.recentBlog && item.recentBlog.bannerImageUrl !== null ? (
                  <div className="single-slide h-100" key={item?._id}>
                    <div className="post-box-layout3 box-border-dark-1 radius-default transition-default">
                      <div className="figure-holder radius-medium h-100">
                        <Link
                          to={`/blog/${item.recentBlog._id}`}
                          className="link-wrap figure-overlay"
                        >
                          <img
                            className="object-fit-cover"
                            height="280"
                            src={item?.recentBlog?.bannerImageUrl}
                            alt="Post"
                          />
                        </Link>
                      </div>
                      <div className="content-holder">
                        <h3 className="entry-title h3-medium color-light-1-fixed underline-animation">
                          <Link
                            to={`/blog/${item.recentBlog._id}`}
                            className="link-wrap"
                          >
                            {item?.recentBlog?.title}
                          </Link>
                        </h3>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )
              )}
            </Slider>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PostAreaCarousel;
